<template>
  <div>
    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="blue-text font-weight-bold">
          <font-awesome-icon
            @click="$router.go(-1)"
            class="mr-3"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
          />
          <span>問い合わせ詳細を表示</span>
        </h4>
      </div>
      <div class="">
        <div class="row m-0" v-if="inquiryData">
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>{{ inquiryData.id }}</p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">会社名:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.company_name || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.name || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">メールアドレス:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.email_address || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせ区分:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.inquiries || 'N/A'
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせ内容:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.message
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">ユーザーID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? inquiryData.user_id
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">作成日時:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? formatDate(inquiryData.created_at)
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">更新日時:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <p>
                  {{
                    inquiryData
                      ? formatDate(inquiryData.updated_at)
                      : 'N/A'
                  }}
                </p>
              </div>
            </div>
          </div>  
        </div>
        <div class="row m-0" v-else>
          <div class="col-12 col-md-6 col-lg-6 cl-xl-6 mb-5">
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">会社名:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">名前:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">メールアドレス:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせ区分:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">問い合わせ内容:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">ユーザーID:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">送信時刻:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-6 col-md-4 col-lg-6 col-xl-5">
                <p class="font-weight-bold">更新時刻:</p>
              </div>
              <div class="col-6 col-md-8 col-lg-6 col-xl-7">
                <b-skeleton class="mt-2" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { adm_inquiryService_getInquiryByID } from '../../services/admin/staging';
import { formatDate } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  data() {
    return {
      search: null,
      inquiryData: null,
      prefecture: null, 
    };
  },

  mounted() {
    this.getResults();
  },
  methods: {
    formatDate,
    getResults() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');
      let id = _this.$route.params.id;
      console.log(id);

      adm_inquiryService_getInquiryByID({ id: id, token: accessToken })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.inquiryData = data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 60px 150px 350px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

.table thead {
  background: #fff;
}

.table thead th {
  border-bottom: 0;
  color: #000;
  font-weight: bold !important;
  text-align: center;
  border-top: 0;
  font-weight: 400;
}

.table tbody td {
  border: none;
  cursor: pointer;
}

.table tbody tr {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.table tbody tr:hover {
  background: #d1d1d1;
}

/* Custom Style */
#table-top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #table-top-ation {
    display: block;
  }
}

#table-top-ation .search {
  display: flex;
  margin-right: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .search {
    margin-bottom: 14px;
  }
}

#table-top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#table-top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#table-top-ation .search input:focus {
  outline: none;
}

#table-top-ation .add-user {
  width: 200px;
}

@media only screen and (max-width: 767px) {
  #table-top-ation .add-user {
    width: 100%;
  }
}
</style>
